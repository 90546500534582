import React, { useState, useEffect, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { isEqual, omitBy } from 'lodash';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Theme } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { withIsServer } from '@components/hoc';
import { Icon } from '@components/common/generic-icon';
import { BoardBasisSearch, Icons } from '@model/common';
import { Results, Result } from '@model/iceberg/deal-finder/deal-finder';
import { WhatsApp as WhatsAppIcon, Share as ShareIcon } from '@mui/icons-material';
import { BLANK_WINDOW } from '@util/blank-window';
import { getQueryAsObject } from '@util/common';
import { getAdults, getChildren } from '@util/deal-finder';
import { removeSearchTokenFromPath } from '@util/path';
import { PlainLinkStyles } from '@styles/common';
import { TestId } from '@components/test-ids';
import { getSearchType } from '@state/deal-finder/dealFinderSelectors';
import { getDealFinderResultsData } from '@state/deal-finder-results/dealFinderResultsSelectors';
import { getHotelPath } from '@state/hotel/hotelSelectors';
import { setShareLink } from '@state/app';
import { getPackageReferences } from '@state/app/package-references/packageReferencesSelectors';
import { getPriceCalendarFilters } from '@state/price-calendar/filter/priceCalendarFilterSelectors';
import { getPriceCalendarSelectedDay } from '@state/price-calendar/monthly/priceCalendarMonthlySelectors';
import { getBasket } from '@state/basket';
import { ToolTip } from '@components/common/tooltip';

const Container = styled.div(({ theme }: { theme: Theme }) => ({
  paddingLeft: theme.custom.spacing.medium,
  paddingRight: theme.custom.spacing.medium
}));

const Title = styled.h5(({ theme }: { theme: Theme }) => ({
  ...(theme.custom.typography.h5 as any),
  marginBottom: 0
}));

const LinksContainer = styled.div(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  ['i, .MuiSvgIcon-root']: {
    color: theme.custom.colors.group2.base,
    fontSize: '2.5rem'
  }
}));

const LinkContainer = styled.div(({ theme }: { theme: Theme }) => ({
  ...(theme.custom.typography.paragraph as any),
  textAlign: 'center'
}));

const PlainLink = styled.a(PlainLinkStyles);

interface ShareLinksProps {
  isServer: boolean;
  suppressHydrationWarning?: boolean;
}

export const ShareLinks = ({ isServer }: ShareLinksProps) => {
  const t: any = useI18NextContext();
  const dispatch = useDispatch();

  const searchType = useSelector(getSearchType);
  const productPath = useSelector(getHotelPath);
  const packageReferences = useSelector(getPackageReferences);
  const previousPackageReferences = usePrevious(packageReferences);
  const priceCalendarFilters = useSelector(getPriceCalendarFilters);
  const previousPriceCalendarFilters = usePrevious(priceCalendarFilters);
  const selectedDay = useSelector(getPriceCalendarSelectedDay);
  const basket = useSelector(getBasket);
  const dealFinderResults = useSelector(getDealFinderResultsData);
  const windowUrl = isServer ? '' : window.location.href;
  const [shareUrl, setShareUrl] = useState(removeSearchTokenFromPath(windowUrl));
  const [showToolTip, setShowToolTip] = useState(false);

  const getBoardBasis = (data: Results) => {
    const { results } = data;
    const selectedPackage = results.find((result: Result) =>
      isEqual(result.leadInPrice.packageReferences, packageReferences)
    );
    const mealPlan = selectedPackage?.leadInPrice.mealPlan;
    if (mealPlan) {
      return BoardBasisSearch[mealPlan];
    }
  };

  const buildUrl = () => {
    const { airport, board, duration, occupancy } = priceCalendarFilters;
    const boards = getBoardBasis(dealFinderResults);
    const queryObj = getQueryAsObject(shareUrl.split('?')[1]);
    const filterParams = omitBy(
      {
        searchType,
        productPath,
        from: airport,
        duration,
        adults: getAdults(occupancy),
        children: getChildren(occupancy),
        date: selectedDay,
        boardBasis: boards || board
      },
      (value) => !value
    );
    const queryParams = {
      ...queryObj,
      ...filterParams
    };
    const url = `${windowUrl.split('?')[0]}?${Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')}`;
    return url;
  };

  useEffect(() => {
    if (shareUrl) {
      dispatch(setShareLink(shareUrl));
    }
  }, [shareUrl]);

  useEffect(() => {
    if (!windowUrl) {
      return;
    }
    let updateShareUrl = false;
    if (!isEqual(packageReferences, previousPackageReferences) && priceCalendarFilters.duration) {
      updateShareUrl = true;
    } else {
      const boardBasis = getBoardBasis(dealFinderResults);
      const currentBoardBasis = /boardBasis=([^&]+)/.exec(shareUrl);
      if (boardBasis && (!currentBoardBasis || currentBoardBasis[1] !== boardBasis)) {
        updateShareUrl = true;
      } else if (priceCalendarFilters.duration && !previousPriceCalendarFilters?.duration) {
        updateShareUrl = true;
      }
    }
    if (updateShareUrl) {
      const shareLink = buildUrl();
      if (shareLink !== shareUrl) {
        setShareUrl(buildUrl());
      }
    }
  }, [packageReferences, priceCalendarFilters, basket]);

  if (isServer) return null;

  const waPath = `https://wa.me/?text=${encodeURIComponent(shareUrl)}`;
  const emailPath = `mailto:?body=${encodeURIComponent(shareUrl)}`;

  const copyToClipboard = (e: MouseEvent) => {
    navigator.clipboard.writeText(shareUrl);
    setShowToolTip(true);
    e.preventDefault();
    window.setTimeout(() => setShowToolTip(false), 2500);
  };

  return (
    <Container data-testid={TestId.shareLinks.main}>
      <Title>{t('share__header')}</Title>
      <p>{t('share__legend')}</p>
      <LinksContainer>
        <LinkContainer>
          <PlainLink href={waPath} target={BLANK_WINDOW}>
            <WhatsAppIcon />
            <div>{t('share__link-label--whatsapp')}</div>
          </PlainLink>
        </LinkContainer>
        <LinkContainer>
          <PlainLink href={emailPath} target={BLANK_WINDOW}>
            <Icon name={Icons.EMAIL} />
            <div>{t('share__link-label--email')}</div>
          </PlainLink>
        </LinkContainer>
        <LinkContainer>
          <ToolTip
            content={<div>{t('share__link-label--copied')}</div>}
            direction="up"
            distance={10}
            isOpen={showToolTip}
          >
            <PlainLink href="#" onClick={copyToClipboard}>
              <ShareIcon />
              <div>{t('share__link-label--copy')}</div>
            </PlainLink>
          </ToolTip>
        </LinkContainer>
      </LinksContainer>
    </Container>
  );
};

export default withTheme(withIsServer(ShareLinks));
