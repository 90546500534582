import { Button, ButtonProps } from '@components/material-ui';
import styled from '@emotion/styled';
import { Icon } from '@components/common/generic-icon';
import { Icons } from '@model/common/icons';
import { ThemeProps } from '@theme/base';

const ArrowIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '1.6rem',
  color: theme.custom.colors.white
}));

export const ActionButton = (props: ButtonProps) => {
  return <Button {...props} endIcon={<ArrowIcon name={Icons.ARROW_RIGHT} />} />;
};
