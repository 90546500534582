import { GlobalAppState } from '@model/state';
import { createSelector } from 'reselect';
import { PriceCalendarMonthlyState } from '@model/state/price-calendar-state';
import { Offer } from '@model/iceberg/service/price-calendar/monthly-response';
import { ISO_8601_DATE_FORMAT } from '@model/common';
import moment from 'moment';

export const getPriceCalendarMonthly = (state: GlobalAppState) => state.priceCalendar.monthly;
export const getPriceCalendarMonthlyOffers = (state: GlobalAppState) =>
  state.priceCalendar.monthly.response.data.prices || [];
export const getPriceCalendarSelectedDay = (state: GlobalAppState) => state.priceCalendar.monthly.date;
export const getPriceCalendarDefaultDay = (state: GlobalAppState) => state.priceCalendar.monthly.defaultDate;
export const getPriceCalendarSelectedOffer = createSelector(
  getPriceCalendarMonthly,
  (monthly: PriceCalendarMonthlyState) => {
    const day: moment.Moment = moment(monthly.date, ISO_8601_DATE_FORMAT);
    return monthly.response.data.prices.find((offer: Offer) => moment(offer.date, ISO_8601_DATE_FORMAT).isSame(day));
  }
);
export const getPriceCalendarDefaultOffer = createSelector(
  getPriceCalendarMonthly,
  (monthly: PriceCalendarMonthlyState) => {
    const day: moment.Moment = moment(monthly.defaultDate, ISO_8601_DATE_FORMAT);
    return monthly.response.data.prices.find((offer: Offer) => moment(offer.date, ISO_8601_DATE_FORMAT).isSame(day));
  }
);
export const getIsPriceCalendarMonthlyLoading = (state: GlobalAppState) => state.priceCalendar.monthly.response.loading;
