import { NewsletterSignUpForm } from './NewsletterSignUpForm';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { CustomerApi } from '@model/iceberg/service/customer/customer-api';
import { GtmBootstrap } from '@model/bootstrap/gtm-bootstrap';

export const NewsletterSignUpFormContainer = () => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleOnSubmit = async (email: string) => {
    if (!executeRecaptcha || !email) return;
    const reCaptcha = await executeRecaptcha('contact');
    const utmParams = new GtmBootstrap().getUtmParams();

    try {
      await new CustomerApi().subscribeToNewsletter({
        email,
        reCaptcha,
        ...utmParams
      });
      setSubmitted(true);
    } catch (e) {
      setSubmitted(false);
    }
  };
  return <NewsletterSignUpForm submitted={submitted} onSubmit={handleOnSubmit} />;
};
