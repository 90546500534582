import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { TestId } from '@components/test-ids';
import { withIsServer } from '@components/hoc';
import { Theme } from '@theme/base';
import { withTheme } from '@emotion/react';
import { BLANK_WINDOW } from '@util/blank-window';
import { EnvConfig } from '@model/config/env-config';
import { AppVariant } from '@model/config/brands';

/* ***************** *
 *       Types       *
 * ***************** */
export interface TrustpilotWidgetProps {
  theme: Theme;
  testId?: string;
  isServer: boolean;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const TrustpilotWidgetContainer = styled.div({});

/* ********************** *
 *    TrustpilotWidget    *
 * ********************** */

export const TrustpilotWidgetComponent = ({ testId, theme, isServer }: TrustpilotWidgetProps) => {
  const ref = React.useRef(null);
  const templateId = EnvConfig.get().TRUSTPILOT_TEMPLATE_ID;
  const businessunitId = EnvConfig.get().TRUSTPILOT_BUSINESSUNIT_ID;

  useEffect(() => {
    if (!isServer && (window as any).Trustpilot && !!templateId && !!businessunitId) {
      if (ref.current) {
        (window as any).Trustpilot.loadFromElement(ref.current, true);
      }
    }
  }, [isServer]);

  if (AppVariant.isWhitelabel() || !templateId || !businessunitId) {
    return null;
  }

  return (
    <TrustpilotWidgetContainer
      ref={ref}
      data-testid={testId || TestId.trustpilotWidget.main}
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id={templateId}
      data-businessunit-id={businessunitId}
      data-style-height="140px"
      data-style-width="100%"
      data-theme="light"
      data-stars="4,5"
      data-review-languages="en"
      data-text-color={theme.custom.colors.group4.base}
    >
      <a href="https://uk.trustpilot.com/review/mercuryholidays.co.uk" target={BLANK_WINDOW} rel="noopener">
        Trustpilot
      </a>
    </TrustpilotWidgetContainer>
  );
};

export const TrustpilotWidget = withTheme(withIsServer(TrustpilotWidgetComponent));
