import React from 'react';
import styled from '@emotion/styled';
import { TestId } from '@components/test-ids';
import { Image } from '@components/common/image';

/* ***************** *
 *       Types       *
 * ***************** */
export interface LazyLoadBackgroundImageProps {
  className?: string;
  testId?: string;
  src: string;
  quality?: number;
  alt?: string;
  sizes?: string;
  onClick?: () => void;
  children?: any;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const LazyLoadContainer: any = styled(Image)({
  position: 'absolute',
  display: 'block',
  minWidth: '100%',
  maxWidth: '100%',
  minHeight: '100%',
  maxHeight: '100%',
  objectFit: 'cover'
});

/* ********************************** *
 *       LazyLoadBackgroundImage      *
 * ********************************** */
export const LazyLoadBackgroundImage = ({
  className,
  src,
  onClick,
  quality,
  alt,
  sizes,
  testId
}: LazyLoadBackgroundImageProps) => {
  return (
    <LazyLoadContainer
      data-testid={testId || TestId.lazyLoad.backgroundImage}
      className={className}
      src={src}
      style={{ objectFit: 'cover' }}
      onClick={onClick}
      quality={quality || 80}
      alt={alt || ''}
      sizes={sizes || '100vw'}
      fill
    />
  );
};
