import styled from '@emotion/styled';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Image } from '@components/common/image';

/* ***************** *
 *       Types       *
 * ***************** */
export interface LazyLoadImageProps {
  className?: string;
  testId?: string;
  src: string;
  width: number;
  height: number;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  sizes?: string;
  alt?: string;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const LazyLoadContainer: any = styled(Image)({});

/* ************************ *
 *       LazyLoadImage      *
 * ************************ */
export const LazyLoadImage = ({ className, testId, src, width, height, objectFit, sizes, alt }: LazyLoadImageProps) => {
  return (
    <LazyLoadContainer
      data-testid={testId || TestId.lazyLoad.backgroundImage}
      className={className}
      src={src}
      width={width}
      height={height}
      style={{ objectFit: objectFit || 'cover' }}
      sizes={sizes || '50vw'}
      fill={!width && !height}
      alt={alt || ''}
    />
  );
};
