import styled from '@emotion/styled';
import { breakpoints, mq } from '@styles/breakpoints';
import React from 'react';
import { ZIndex } from '@styles/z-index';
import { BaseBox } from '@components/common/box/base/BaseBox';
import { mixins } from '@styles/mixins';
import { DesktopContainer } from '@components/common/breakpoints';
import { Pricing } from '@model/iceberg';
import { TestId } from '@components/test-ids';
import { BasketPanelMobile } from '@components/basket/v2/basket-panel/BasketPanelMobile';
import { ThemeProps } from '@theme/base';
import { ButtonColor } from '@components/material-ui';
import { ActionButton } from '@components/basket/v2/common/ActionButton';
import ShareLinks from '@components/share-links';

export interface BasketPanelProps {
  render: any;
  label: string;
  pricing: Pricing;
  expanded: boolean;
  fixed?: boolean;
  top?: number;
  offset?: number;
  children?: any;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
  isGhosting?: boolean;
  onExpand: (payload: boolean) => void;
  onClick: () => void;
  showShareLinks?: boolean;
}

interface PanelProps extends ThemeProps {
  fixed?: boolean;
  top?: number;
  offset?: number;
}
const Panel: any = styled.div(({ theme, fixed, top, offset }: PanelProps) => ({
  position: fixed ? 'relative' : 'sticky',
  top: top || top === 0 ? top : theme.custom.spacing.large,
  bottom: 'auto',
  marginTop: offset || 0,
  zIndex: ZIndex.PRODUCT_PRICE,
  [mq.ie(breakpoints.medium)]: {
    position: 'relative',
    top: theme.custom.spacing.large
  }
}));

const ContainerColor = styled(BaseBox)({
  borderRadius: 3
});

const Container: any = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  position: 'fixed',
  width: '100%',
  bottom: 0,
  left: 0,
  zIndex: ZIndex.PRODUCT_PRICE,
  alignItems: 'center',
  height: 'auto',
  [mq.medium]: {
    margin: 0,
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'flex-start'
  }
});

const PriceContainer: any = styled(ContainerColor)(({ theme }: ThemeProps) => ({
  ...mixins(theme).card,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  bottom: 0,
  left: 0,
  alignItems: 'center',
  position: 'relative',
  [mq.medium]: {
    width: '100%',
    margin: 0,
    flexDirection: 'column',
    marginBottom: theme.custom.spacing.large,
    alignItems: 'flex-start'
  }
}));

const BodyContainer: any = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  transition: `opacity ${theme.custom.transitions.default}`,
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'flex-start'
}));

export const StyledButton = styled(ActionButton)(({ theme }: ThemeProps) => ({
  marginTop: 0,
  width: '50%',
  [mq.medium]: {
    marginTop: theme.custom.spacing.large,
    width: '100%'
  }
}));

export const BASKET_PANEL_ID = 'basketPanel';

export const BasketPanel = ({
  render,
  label,
  onClick,
  children,
  disabled,
  pricing,
  className,
  fixed,
  top,
  offset,
  onExpand,
  expanded,
  isLoading,
  isGhosting,
  showShareLinks
}: BasketPanelProps) => {
  const handleOnClick = () => {
    if (!isLoading) {
      onClick();
    }
  };
  return (
    <Panel id={BASKET_PANEL_ID} data-testid={TestId.basketPanel} fixed={fixed} top={top} offset={offset}>
      <Container className={className}>
        <DesktopContainer>
          <PriceContainer>
            <BodyContainer>
              {render}
              <StyledButton
                label={label}
                disabled={disabled}
                isGhosting={isGhosting}
                color={ButtonColor.SECONDARY}
                onClick={handleOnClick}
              />
            </BodyContainer>
          </PriceContainer>
          {children}
        </DesktopContainer>
        <BasketPanelMobile
          pricing={pricing}
          render={render}
          expanded={expanded}
          label={label}
          isGhosting={isGhosting}
          onClick={onClick}
          onExpand={onExpand}
        />
      </Container>
      {showShareLinks && <ShareLinks suppressHydrationWarning />}
    </Panel>
  );
};
