import React from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { mixins } from '@styles/mixins';
import { ThemeProps } from '@theme/base';

export interface BaseBoxProps {
  children?: Array<JSX.Element> | JSX.Element;
  className?: string;
  testId?: string;
}

const Container: any = styled.div(({ theme }: ThemeProps) => ({
  ...mixins(theme).card,
  padding: theme.custom.spacing.large
}));

const BaseBoxComponent = ({ children, className, testId }: BaseBoxProps) => (
  <Container className={className} data-testid={testId}>
    {children}
  </Container>
);

export const BaseBox = withTheme(BaseBoxComponent);
