import React from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Grid } from '@mui/material';
import { Theme, ThemeProps } from '@theme/base';
import { ComponentContainer } from '@styles/layout';
import { breakpoints, mq } from '@styles/breakpoints';
import { TestId } from '@components/test-ids';
import { useI18NextContext } from '@components/hooks';
import { OpeningTimesTable } from '../../common/opening-times/opening-times-table';
import { Footer as FooterModel } from '@model/content';
import { Type } from '@components/footer/type';
import Markdown from 'react-markdown';
import { LinkComponent } from '@components/common/description/TextMarkdown';
import { Logos } from '@components/footer/main';
import { AbtaAtol } from '@components/footer/main/AbtaAtol';
import { getImageUrl } from '@util/images';

/* ***************** *
 *       Types       *
 * ***************** */
export interface CheckoutFooterProps extends FooterModel {
  theme?: Theme;
  testId?: string;
  hasBasketPanel?: boolean;
}

/* ***************** *
 *       Styles      *
 * ***************** */
interface ContainerProps extends ThemeProps {
  hasBasketPanel: boolean;
}
const Container: any = styled.footer(({ theme, hasBasketPanel }: ContainerProps) => ({
  backgroundColor: theme.custom.colors.group10.lighter,
  padding: `${theme.custom.spacing.xxLarge}px 0`,
  paddingBottom: hasBasketPanel ? theme.custom.spacing.xxxLarge + theme.custom.spacing.medium : 0,

  [mq.medium]: {
    paddingBottom: 0
  },

  [mq.large]: {
    padding: `${theme.custom.spacing.xxxLarge}px 0`
  }
}));

const Title = styled.h4(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h4 as any),
  marginTop: 0
}));

const FAQsContainer = styled.div(({ theme }: ThemeProps) => ({
  marginTop: theme.custom.spacing.xLarge
}));

const FAQWrapper = styled.div(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.xLarge
}));

const FAQQuestion = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  margin: 0,
  marginBottom: theme.custom.spacing.xSmall,
  fontWeight: 500
}));

const FAQAnswer = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  margin: 0
}));

const PhoneNumber = styled.div(({ theme }: ThemeProps) => ({
  p: {
    ...(theme.custom.typography.h4 as any),
    margin: 0
  }
}));

const RightColumnGrid: any = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [mq.small]: {
    textAlign: 'right'
  }
});

/* ***************************** *
 *    CheckoutFooterComponent    *
 * ***************************** */

export const CheckoutFooterComponent = ({
  theme,
  title,
  faqs = [],
  openingTimes,
  phoneNumber,
  testId,
  hasBasketPanel,
  logo
}: CheckoutFooterProps) => {
  const t: any = useI18NextContext();

  const { openingTimeDays = [], openingTimeHours = [], altTitle: openingTimesTitle } = openingTimes || {};

  /* *** RENDERERS *** */
  const renderFAQs = () =>
    faqs.map((faq) => (
      <FAQWrapper key={faq.title}>
        <FAQQuestion>{faq.title}</FAQQuestion>
        <FAQAnswer>{faq.description}</FAQAnswer>
      </FAQWrapper>
    ));

  return (
    <Container data-testid={testId || TestId.footer.checkoutFooter.main} hasBasketPanel={hasBasketPanel}>
      <ComponentContainer>
        <Grid container spacing={theme!.custom.gridSpacing.default}>
          <Grid item xs={12} sm={7}>
            <Title>{t(title)}</Title>
            <FAQsContainer>{renderFAQs()}</FAQsContainer>
          </Grid>
          <RightColumnGrid item xs={12} sm={5}>
            <PhoneNumber>
              <Markdown children={t(phoneNumber)} components={LinkComponent} />
            </PhoneNumber>
            <OpeningTimesTable
              days={openingTimeDays}
              hours={openingTimeHours}
              title={openingTimesTitle}
              variant={Type.CHECKOUT}
            />
            {logo && (
              <Logos>
                <AbtaAtol src={getImageUrl(logo.url, breakpoints.small, true)} alt={t('common__label--abta-atol')} />
              </Logos>
            )}
          </RightColumnGrid>
        </Grid>
      </ComponentContainer>
    </Container>
  );
};

export const CheckoutFooter = withTheme(CheckoutFooterComponent);
