import styled from '@emotion/styled';
import React from 'react';
import { ThemeProps } from '@theme/base';

const Container = styled.div(({ theme }: ThemeProps) => ({
  maxWidth: theme.custom.contentSizes.maxContentWidth,
  width: '100%'
}));

export interface MaxContentWidthProps {
  className?: string;
  children?: any;
  testId?: string;
}

export const MaxContentWidth = ({ className, children, testId }: MaxContentWidthProps) => (
  <Container data-testid={testId} className={className}>
    {children}
  </Container>
);
