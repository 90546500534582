import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';

export const DesktopContainer = styled.div({
  display: 'none',
  [mq.medium]: {
    display: 'block',
    width: '100%'
  }
});

export const MobileContainer = styled.div({
  display: 'block',
  width: '100%',
  [mq.medium]: {
    display: 'none'
  }
});
