import { Icon } from '@components/common/generic-icon';
import { ButtonColor } from '@components/material-ui';
import { Icons } from '@model/common/icons';
import { MobileContainer } from '@components/common/breakpoints';
import React from 'react';
import { Amount } from '@model/common';
import { useI18NextContext } from '@components/hooks';
import styled from '@emotion/styled';
import Drawer from '@mui/material/Drawer';
import { ThemeProps } from '@theme/base';
import IconButton from '@mui/material/IconButton';
import { mq } from '@styles/breakpoints';
import { ZIndex } from '@styles/z-index';
import { mixins } from '@styles/mixins';
import { Pricing } from '@model/iceberg';
import { BaseBox } from '@components/common/box/base/BaseBox';
import { ActionButton } from '@components/basket/v2/common/ActionButton';
import { SkeletonElement, SkeletonVariant } from '@components/common/skeleton-loading';

const ContainerColor = styled(BaseBox)(({ theme }: ThemeProps) => ({
  background: theme.custom.colors.white,
  borderWidth: 1
}));

const PriceContainer: any = styled(ContainerColor)(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.medium,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  bottom: 0,
  left: 0,
  alignItems: 'center',
  position: 'relative'
}));

const BodyContainer: any = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  transition: `opacity ${theme.custom.transitions.default}`,
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center'
}));

const ViewBasket = styled.div({});

const StyledDrawer = styled(Drawer)({
  '.MuiPaper-root': {
    height: '100%'
  }
});

const DrawerContainer = styled.div(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.large
}));

const Heading = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: theme.custom.spacing.large
}));

const PriceBreakdown = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h4 as any)
}));

const StyledIconButton = styled(IconButton)({
  '&.MuiIconButton-root': {
    padding: 0
  }
});

const AmountContainer = styled.div({
  display: 'flex',
  alignItems: 'center'
});

const AmountLabel: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h4 as any),
  color: theme.custom.colors.group4.base,
  textTransform: 'none',
  lineHeight: 'normal',
  fontWeight: 'bold',
  [mq.medium]: {
    flex: 1,
    textAlign: 'right'
  }
}));

const Label = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  color: theme.custom.colors.group4.dark,
  marginLeft: theme.custom.spacing.xSmall,
  flex: 1,
  textTransform: 'capitalize',
  [mq.medium]: {
    ...theme.custom.typography.paragraph,
    color: theme.custom.colors.group4.base,
    marginLeft: 0
  }
}));

const StyleMobileButton = styled(ActionButton)({
  width: '100%'
});

const DropdownConfirmation = styled.div(({ theme }: ThemeProps) => ({
  position: 'fixed',
  height: 100,
  bottom: 0,
  width: '100%',
  left: 0,
  paddingLeft: theme.custom.spacing.medium,
  paddingRight: theme.custom.spacing.medium,
  zIndex: ZIndex.SEARCH_DROPDOWN,
  ...(theme.custom.typography.paragraph as any),
  ...mixins(theme).link,
  borderTop: `1px solid ${theme.custom.colors.group10.light}`,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row-reverse',
  backgroundColor: theme.custom.colors.white
}));

const Scroll: any = styled.div({
  marginBottom: 100,
  height: '100%',
  overflow: 'auto'
});

const Chevron = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '1.2rem',
  marginLeft: theme.custom.spacing.xSmall
}));

const ViewBasketContainer = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  ...(theme.custom.typography.paragraph as any),
  ...mixins(theme).link,
  textDecoration: 'none',
  i: {
    ...mixins(theme).link,
    textDecoration: 'none'
  },
  ':hover': {
    i: {
      color: theme.custom.states.link.hover
    }
  }
}));

const CloseIcon = styled(Icon)({
  fontSize: '2rem'
});

export interface BasketPanelMobileProps {
  pricing: Pricing;
  label: string;
  render: any;
  expanded: boolean;
  isGhosting?: boolean;
  onExpand: (payload: boolean) => void;
  onClick: () => void;
}

export const BasketPanelMobile = ({
  pricing,
  label,
  render,
  expanded,
  isGhosting,
  onClick,
  onExpand
}: BasketPanelMobileProps) => {
  const perPerson: Amount = new Amount(pricing.perPerson.amount, pricing.perPerson.currency.code);
  const total: Amount = new Amount(pricing.total.amount, pricing.total.currency.code);
  const t: any = useI18NextContext();
  return (
    <MobileContainer>
      <StyledDrawer
        open={expanded}
        onClose={() => onExpand(false)}
        transitionDuration={{ enter: 500, exit: 600 }}
        anchor={'bottom'}
      >
        <DrawerContainer>
          <Heading>
            <PriceBreakdown>{t('basket-viewer__title--label')}</PriceBreakdown>
            <StyledIconButton aria-label="Close" onClick={() => onExpand(false)}>
              <CloseIcon name={Icons.CLEAR} />
            </StyledIconButton>
          </Heading>
          <Scroll>{render}</Scroll>
          <DropdownConfirmation>
            <StyleMobileButton label={label} onClick={onClick} color={ButtonColor.SECONDARY} />
          </DropdownConfirmation>
        </DrawerContainer>
      </StyledDrawer>
      <PriceContainer>
        <BodyContainer>
          <div>
            {isGhosting && (
              <>
                <SkeletonElement variant={SkeletonVariant.TEXT} width={150} />
                <SkeletonElement variant={SkeletonVariant.TEXT} width={150} />
              </>
            )}
            {!isGhosting && (
              <>
                <AmountContainer>
                  <AmountLabel>{perPerson.format()}</AmountLabel>
                  <Label>{t('common__per-person--label')}</Label>
                </AmountContainer>
                <AmountContainer>
                  <AmountLabel>{total.format()}</AmountLabel>
                  <Label>{t('basket-viewer_summary--total')}</Label>
                </AmountContainer>
              </>
            )}
          </div>
          <ViewBasketContainer>
            <ViewBasket onClick={() => onExpand(true)}>{t('basket-viewer__view-basket--label')}</ViewBasket>
            <Chevron name={Icons.CHEVRON_UP} />
          </ViewBasketContainer>
        </BodyContainer>
      </PriceContainer>
    </MobileContainer>
  );
};
