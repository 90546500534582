import { LazyLoadImage } from '@components/common/lazy-load';

export interface AbtaAtolProps {
  alt?: string;
  src: string;
}

export const AbtaAtol = (props: AbtaAtolProps) => {
  return <LazyLoadImage width={150} height={40} {...props} />;
};
