import styled from '@emotion/styled';
import { Link as ContentnfulLink } from '@model/contentful';
import { mq } from '@styles/breakpoints';
import { mixins } from '@styles/mixins';
import { BLANK_WINDOW } from '@util/blank-window';
import React from 'react';
import { Page } from '@model/common';
import { useI18NextContext } from '@components/hooks';
import { ThemeProps } from '@theme/base';

export interface LinksProps {
  links: Array<ContentnfulLink>;
  testId: string;
  isWhiteLabel: boolean;
}

const Link: any = styled.a(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 500,
  display: 'block',
  marginBottom: theme.custom.spacing.large,
  ...mixins(theme).textShadowLight,
  ...mixins(theme).truncate,
  textDecoration: 'none',
  color: theme.custom.colors.white,
  transition: `color ${theme.custom.transitions.default}`,
  cursor: 'pointer',
  userSelect: 'none',
  ['&:visited, &:active']: {
    color: theme.custom.colors.white
  },
  ['&:hover, &:visited:hover, &:active:hover']: {
    color: theme.custom.colors.group9.base
  },
  columnBreakInside: 'avoid',
  backfaceVisibility: 'hidden'
}));

const LinkContainer = styled.div({
  padding: 0,
  columnCount: 2,
  width: '100%',
  maxWidth: 380,
  [mq.medium]: {
    maxWidth: 440
  }
});

export const Links = (props: LinksProps) => {
  const t: any = useI18NextContext();
  return (
    <LinkContainer data-testid={props.testId}>
      {props.links.map((link: ContentnfulLink) => (
        <Link prefetch={false} key={link.title} href={link.url} target={link.newWindow ? BLANK_WINDOW : ''}>
          {link.title}
        </Link>
      ))}
      {!props.isWhiteLabel && <Link href={Page.AGENTS}>{t('agent__link--title')}</Link>}
    </LinkContainer>
  );
};
