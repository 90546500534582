import React from 'react';
import { removeSearchTokenFromPath } from '@util/path';
import { withIsServer } from '@components/hoc';
import { useI18NextContext } from '@components/hooks';

export interface ShareLinkMetaProps {
  title?: string;
  description?: string;
  imageUrl?: string;
  isServer: boolean;
}

export const OG_BREAKPOINTS = {
  width: '1200',
  height: '600'
};

export const shareLinkMeta = withIsServer(({ title, description, imageUrl, isServer }: ShareLinkMetaProps) => {
  if (isServer) {
    return null;
  }
  const url = removeSearchTokenFromPath(window.location.href);
  const t: any = useI18NextContext();
  const imageType =
    imageUrl && `image/${/\.jpg$/.test(imageUrl) ? 'jpeg' : imageUrl?.substring(imageUrl?.lastIndexOf('.') + 1)}`;

  return (
    <>
      <meta property="og:locale" content="en_GB" />
      <meta property="og:type" content="website" />
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={t('common__brand_display')} />
      <meta property="og:image" content={`${imageUrl}?w=${OG_BREAKPOINTS.width}&h=${OG_BREAKPOINTS.height}`} />
      <meta property="og:image:width" content={OG_BREAKPOINTS.width} />
      <meta property="og:image:height" content={OG_BREAKPOINTS.height} />
      <meta property="og:image:type" content={imageType} />
    </>
  );
});
